import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/joy/Stack";
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import moment from "moment";
import { ToastContainer } from "react-toastify";

export default function TestsList() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [openAssignUser, setOpenAssignUser] = useState(false);
  const [tests, setTests] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [filters, setFilters] = useState({
    board: "",
    grade: "",
    test_type: "",
    name: "",
  });

  const [selectedTest, setSelectedTest] = useState("");

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [types, setTypes] = useState([]);

  const [testNames, setTestNames] = useState([]);

  const fetchBoards = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .catch((e) => {});
  };

  const fetchGrades = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}grade/get-all-grades`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        setGrades([]);
      });
  };

  const fetchTestTypes = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}test-type/test-types`)
      .then((res) => {
        setTypes(res.data.TestType);
      })
      .catch((e) => {
        setTypes([]);
      });
  };

  const fetchAllTests = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}test/get-all-tests`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }
    await axios
      .get(url)
      .then((res) => {
        if (res.data.tests.length > 0) {
          console.log(res.data.tests);
          const uniqueNames = [
            ...new Set(res.data.tests.map((test) => test.name)),
          ];
          setTestNames(uniqueNames);
        }
      })
      .catch((e) => {});
  };

  const changeStatus = async (test) => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}test/toggle-active-status/${test._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const deleteTest = async (test_id) => {
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}test/delete-test/${test_id}`)
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const fetchTests = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}test/get-all-tests`;
    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }

    await axios
      .get(url, { headers: { jwt: token } })
      .then((res) => {
        setTests(res.data.tests);
      })
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  const tests_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Test Name",
        field: "test_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 150,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Test Type",
        field: "test_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Start Time",
        field: "start_time",
        sort: "asc",
        width: 150,
      },
      {
        label: "End Time",
        field: "end_time",
        sort: "asc",
        width: 150,
      },

      {
        label: "Assign Students",
        field: "assign",
        sort: "asc",
        width: 150,
      },

      {
        label: "Students Assigned",
        field: "users_assigned",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tests?.map((test, index) => {
      return {
        s_no: <td>{index + 1}</td>,
        test_name: test?.name,
        board: test?.boards?.name,
        grade: test?.grades?.name,
        image: (
          <td>
            <img
              style={{
                maxWidth: "4.5rem",
                maxHeight: "4.5rem",
                objectFit: "cover",
              }}
              alt="..."
              src={process.env.REACT_APP_BASE_URL + test.image}
            ></img>
          </td>
        ),
        test_type: test?.test_types?.name,
        start_time: (
          <td>{moment(test.start_time).format("DD/MM/YYYY hh:mm A")}</td>
        ),
        end_time: <td>{moment(test.end_time).format("DD/MM/YYYY hh:mm A")}</td>,
        assigned_test: (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              size="small"
              onClick={() => setOpenAssignUser(true)}
            >
              Assign
            </Button>
          </div>
        ),
        assign: (
          <Link to={`/assign-students?id=${test._id}`}>
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              Assign
            </button>
          </Link>
        ),
        users_assigned: (
          <td>
            <div style={{ display: "flex" }}>
              <Link to={`/users-assigned?id=${test._id}`}>
                <div
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "50%",
                    color: "#fff",
                    background: "#ff6b6b",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {test.users.length}
                </div>
              </Link>
            </div>
          </td>
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link to="/edit-test" state={{ Test: test }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedTest(test);
                setWarning({
                  text: `Do you want to change the status to ${
                    test.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className={
                test.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {test.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Test?`,
                  type: "delete",
                });
                setSelectedTest({ name: test.name, id: test._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchBoards();
    if (boards.length > 0) {
      fetchGrades();
      if (grades.length > 0) {
        fetchTestTypes();
      }
    }
    fetchAllTests();
    fetchTests();
  }, [callApi]);

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Manage Tests"}></Breadcrumb>
            <Link to="/add-test">
              <Button className="btn btn-primary">Add Test</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <CardHeader title="Select Board" />
                <FormControl sx={{ width: 150 }}>
                  <InputLabel id="demo-simple-select-label">Board</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Board"
                    value={filters.board}
                    onChange={(e) => {
                      setFilters({ ...filters, board: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {boards.length > 0 &&
                      boards.map((board) => {
                        return (
                          <MenuItem value={board._id}>{board.name}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Select Grade" />
                <FormControl sx={{ width: 150 }}>
                  <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Grade"
                    id="demo-simple-select"
                    value={filters.grade}
                    onChange={(e) => {
                      setFilters({ ...filters, grade: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {grades.length > 0 &&
                      grades.map((grade) => {
                        return (
                          <MenuItem value={grade._id}>{grade.name}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Test Type" />
                <FormControl sx={{ width: 160 }}>
                  <InputLabel id="demo-simple-select-label">
                    Test Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Test Type"
                    id="demo-simple-select"
                    value={filters.test_type}
                    onChange={(e) => {
                      setFilters({ ...filters, test_type: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {types.length > 0 &&
                      types.map((type) => {
                        return (
                          <MenuItem key={type._id} value={type._id}>
                            {type.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Select Test Name" />
                <FormControl sx={{ width: 160 }}>
                  <InputLabel id="demo-simple-select-label">
                    Test Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Test Name"
                    id="demo-simple-select"
                    value={filters.name}
                    onChange={(e) => {
                      setFilters({ ...filters, name: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {testNames.length > 0 &&
                      testNames.map((testName, index) => {
                        return (
                          <MenuItem key={index} value={testName}>
                            {testName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchTests();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Assign Tests" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={tests_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
        {/* Assign Test */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openAssignUser}
          onClose={() => setOpenAssignUser(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Assign Test
            </Typography>

            <Stack spacing={2}>
              <div style={{ marginTop: "2rem" }}>
                <CardHeader title="Choose Board" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Board Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Board Name"
                  >
                    <MenuItem value={10}>CBSE</MenuItem>
                    <MenuItem value={20}>ICSE</MenuItem>
                    <MenuItem value={30}>WB</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginTop: "2rem" }}>
                <CardHeader title="Choose Grade" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Grade"
                  >
                    <MenuItem value={10}>Primary</MenuItem>
                    <MenuItem value={20}>Secondary</MenuItem>
                    <MenuItem value={30}>Higher Secondary</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  className="btn btn-primary"
                  variant="contained"
                  sx={{ width: "8rem" }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Stack>
          </Sheet>
        </Modal>

        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedTest);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteTest(selectedTest.id, selectedTest.name);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
