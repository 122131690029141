import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import axios from "axios";

export default function CounsellorsList() {
  const navigate = useNavigate();

  const [showTimeSlots, setShowTimeSlots] = useState(false);

  const [callApi, setCallApi] = useState(false);

  const [loading, setLoading] = useState(false);

  const [counsellors, setCounsellors] = useState([]);

  const [subjects, setSubjects] = useState([]);

  const [selectedCounsellor, setSelectedCounsellor] = useState("");

  const [cities, setCities] = useState([]);

  const [qualifications, setQualifications] = useState([]);
  const [SubjectArea, setSubjectArea] = useState([]);

  const [days, setDays] = useState([]);

  const token = localStorage.getItem("token");

  const [filters, setFilters] = useState({
    subject: "",
    city: "",
    experience: "",
    qualification: "",
  });

  const fetchCounsellorsList = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}counsellor/get-counsellors`;

    const queryParameters = Object.entries(filters)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    if (queryParameters) {
      url += `?${queryParameters}`;
    }

    await axios
      .get(url, { headers: { jwt: token } })
      .then((res) => {
        console.log(res.data);
        setCounsellors(res.data.Counsellors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAllCounsellorsList = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}counsellor/get-counsellors`;

    await axios
      .get(url, { headers: { jwt: token } })
      .then((res) => {
        const uniqueQualifications = [
          ...new Set(
            res.data.Counsellors.map((counsellor) => counsellor.qualification)
          ),
        ];
        setQualifications(uniqueQualifications);
        const uniqueCities = [
          ...new Set(res.data.Counsellors.map((counsellor) => counsellor.city)),
        ];
        setCities(uniqueCities);
        const SubjectArea = [
          ...new Set(res.data.Counsellors.map((subjects) => subjects.name)),
        ];
        setSubjectArea(SubjectArea);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  const fetchSubjects = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}subject-area/get-all-subject-area`)
      .then((res) => {
        res.data.SubjectArea.map((subject) => {
          setSubjects((preValue) => {
            return [...preValue, subject.name.toLowerCase()];
          });
        });
      })
      .catch((e) => {
        setSubjects([]);
      });
  };

  const calculateDays = (slots) => {
    let SlotDays = [];
    slots.map((slot) => {
      if (!SlotDays.includes(slot.day)) {
        SlotDays.push(slot.day);
      }
    });
    setDays(SlotDays);
  };

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const deleteCounsellor = async (counsellor) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}counsellor/remove-counsellor/${counsellor._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const changeStatus = async (counsellor) => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}counsellor/toggle-active/${counsellor._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const counsellor_data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Age",
        field: "age",
        sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 100,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Qualification",
        field: "qualification",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subject Area",
        field: "subject_area",
        sort: "asc",
        width: 100,
      },
      {
        label: "Profession",
        field: "profession",
        sort: "asc",
        width: 100,
      },
      {
        label: "Experience",
        field: "experience",
        sort: "asc",
        width: 100,
      },
      {
        label: "Streams",
        field: "streams",
        sort: "asc",
        width: 100,
      },
      {
        label: "Time Slots",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: counsellors?.map((counsellor) => {
      console.log(counsellor.interested_streams);

      return {
        name: counsellor.name,
        age: counsellor.age,
        email: counsellor.email,
        phone: counsellor.mobile,
        city: counsellor.city,
        qualification: counsellor.qualification.name,
        subject_area: counsellor.subjects.map((subject, index) => {
          return subject.name + ",";
        }),
        profession: counsellor.profession.name,
        experience: `${counsellor.experience} Years`,
        streams: counsellor.interested_streams.map((stream, index) => {
          return stream.name + ",";
        }),
        time: (
          <td
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <button
              onClick={() => {
                setShowTimeSlots(true);
                setSelectedCounsellor(counsellor);
                calculateDays(counsellor.slots);
              }}
              type="button"
              style={{
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-primary"
            >
              <i className="fas fa-eye"></i>
            </button>
          </td>
        ),
        actions: (
          <td
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Link to="/edit-counsellor" state={{ Counsellor: counsellor }}>
              <button
                type="button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-primary"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedCounsellor(counsellor);
                setWarning({
                  text: `Do you want to change the status to ${
                    counsellor.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                counsellor.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {counsellor.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Counsellor?`,
                  type: "delete",
                });
                setSelectedCounsellor(counsellor);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchCounsellorsList();
  }, [callApi]);

  useEffect(() => {
    fetchAllCounsellorsList();
  }, []);

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"Counsellors List"}></Breadcrumb>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Subject" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Subject"
                    value={filters.subject}
                    onChange={(e) => {
                      setFilters({ ...filters, subject: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {subjects.length > 0 &&
                      subjects.map((subject) => {
                        return <MenuItem value={subject}>{subject}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="City" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="City"
                    value={filters.city}
                    onChange={(e) => {
                      setFilters({ ...filters, city: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {cities.length > 0 &&
                      cities.map((city) => {
                        return <MenuItem value={city}>{city}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </Col>

              <Col xl={2}>
                <CardHeader title="Experience" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Experience
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Experience"
                    value={filters.experience}
                    onChange={(e) => {
                      setFilters({ ...filters, experience: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col xl={3}>
                <CardHeader title="Qualification" />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Qualification
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Qualification"
                    value={filters.qualification}
                    onChange={(e) => {
                      setFilters({ ...filters, qualification: e.target.value });
                      setCallApi(!callApi);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {qualifications.length > 0 &&
                      qualifications.map((qualification) => {
                        return (
                          <MenuItem value={qualification}>
                            {qualification}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Col>
              <Col xl={1}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchCounsellorsList();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Counsellors List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={counsellor_data}
                          style={{
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            marginTop: "1.5rem",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Assign Student */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showTimeSlots}
          onClose={() => setShowTimeSlots(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "90%",
              height: "40rem",
              overflowY: "scroll",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />

            <Row>
              <Col xl={12}>
                <Typography
                  id="basic-modal-dialog-title"
                  component="h2"
                  level="inherit"
                  fontSize="1.25em"
                  mb={1}
                >
                  Time Slots
                </Typography>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  {days.length > 0 &&
                    days.map((day, index) => {
                      return (
                        <Row
                          key={index}
                          xs={1}
                          md={1}
                          xl={1}
                          style={{ marginTop: "2rem" }}
                        >
                          <Col xl={3}>
                            <CardHeader title="Selected Day" />
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={day}
                                disabled
                              >
                                <MenuItem value={"monday"}>Monday</MenuItem>
                                <MenuItem value={"tuesday"}>Tuesday</MenuItem>
                                <MenuItem value={"wednesday"}>
                                  Wednesday
                                </MenuItem>
                                <MenuItem value={"thursday"}>Thursday</MenuItem>
                                <MenuItem value={"friday"}>Friday</MenuItem>
                                <MenuItem value={"saturday"}>Saturday</MenuItem>
                                <MenuItem value={"sunday"}>Sunday</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>

                          <Col xl={3} />

                          <Col xl={6}>
                            <CardHeader title="Selected Time Slots" />

                            <Row>
                              {selectedCounsellor.slots.length > 0 &&
                                selectedCounsellor.slots.map((slot, index) => {
                                  if (slot.day === day) {
                                    return (
                                      <Col key={index} xl={4}>
                                        <label className="checkbox-button-container">
                                          <input
                                            type="checkbox"
                                            disabled
                                            value={`${slot.start_time} - ${slot.end_time}`}
                                          />
                                          <div
                                            className="checkbox-button"
                                            disabled
                                          >{`${slot.start_time} - ${slot.end_time}`}</div>
                                        </label>
                                      </Col>
                                    );
                                  }
                                })}
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                </Box>
              </Col>
            </Row>
          </Sheet>
        </Modal>

        {/* Warning Counsellor Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedCounsellor);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteCounsellor(selectedCounsellor);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
